import React, { useState, useRef } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  LinearProgress,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  TextField,
  Slider,
  Grid,
  Fade,
  CircularProgress
} from '@mui/material';
import {
  CloudUpload,
  MovieFilter,
  Settings,
  Download,
  VolumeUp,
  Speed,
  HighQuality,
  AspectRatio,
  ArrowBack
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import api from '../services/api';

const DropZone = styled(Paper)(({ theme, isDragging }) => ({
  padding: theme.spacing(8),
  border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isDragging ? theme.palette.primary.lighter : theme.palette.background.paper,
  cursor: 'pointer',
  transition: theme.transitions.create(['border', 'background-color'], {
    duration: theme.transitions.duration.shorter,
  }),
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.lighter,
    borderColor: theme.palette.primary.main,
  },
}));

const VideoProcessor = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [videoInfo, setVideoInfo] = useState(null);
  const [processedVideo, setProcessedVideo] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [parameters, setParameters] = useState({
    dimensions: '',
    bitrate: '',
    audioBitrate: '',
    volume: 0,
  });

  const [modifiedParams, setModifiedParams] = useState({
    dimensions: false,
    bitrate: false,
    audioBitrate: false,
    volume: false
  });

  const steps = ['Upload', 'Configuration', 'Traitement', 'Téléchargement'];

  const handleParameterChange = (param, value) => {
    setParameters(prev => ({
      ...prev,
      [param]: value
    }));
    setModifiedParams(prev => ({
      ...prev,
      [param]: true
    }));
  };

  const resetUpload = () => {
    setCurrentStep(0);
    setVideoInfo(null);
    setParameters({
      dimensions: '',
      bitrate: '',
      audioBitrate: '',
      volume: 0,
    });
    setModifiedParams({
      dimensions: false,
      bitrate: false,
      audioBitrate: false,
      volume: false,
    });
    setUploadProgress(0);
  };

  const handleFileUpload = async (file) => {
    try {
      setUploadProgress(0);
      const response = await api.uploadVideo(file, (progress) => {
        setUploadProgress(progress);
      });

      setVideoInfo(response.video_info);
      enqueueSnackbar('Vidéo uploadée avec succès !', { variant: 'success' });
      setCurrentStep(1);
    } catch (error) {
      enqueueSnackbar('Erreur lors de l\'upload: ' + error.message, { variant: 'error' });
    }
  };

  const handleProcessVideo = async () => {
    try {
      const processParams = {};
      Object.keys(parameters).forEach(key => {
        if (modifiedParams[key]) {
          processParams[key] = parameters[key];
        }
      });

      setIsProcessing(true);
      setCurrentStep(2);
      setProcessingProgress(0);

      const progressInterval = setInterval(() => {
        setProcessingProgress(prev => {
          if (prev >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return prev + 1;
        });
      }, 300);

      const response = await api.processVideo(videoInfo.filename, processParams);
      
      clearInterval(progressInterval);
      setProcessingProgress(100);
      setProcessedVideo(response.processed_filename);
      enqueueSnackbar('Traitement terminé avec succès !', { variant: 'success' });
      setCurrentStep(3);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setCurrentStep(1);
    } finally {
      setIsProcessing(false);
    }
  };

  const formatSize = (size) => {
    const mb = size / (1024 * 1024);
    return `${mb.toFixed(2)} MB`;
  };

  const formatBitrate = (bitrate) => {
    return `${Math.round(bitrate / 1000)} kbps`;
  };
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h3" align="center" gutterBottom sx={{ 
          fontWeight: 'bold',
          background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Studio de Traitement Vidéo
        </Typography>

        <Stepper activeStep={currentStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 4 }}>
          {/* Étape 1: Upload */}
          {currentStep === 0 && (
            <Fade in>
              <Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  style={{ display: 'none' }}
                  accept="video/*"
                />
                
                <DropZone
                  isDragging={isDragging}
                  onDragEnter={(e) => {
                    e.preventDefault();
                    setIsDragging(true);
                  }}
                  onDragLeave={() => setIsDragging(false)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    setIsDragging(false);
                    const file = e.dataTransfer.files[0];
                    if (file && file.type.startsWith('video/')) {
                      handleFileUpload(file);
                    }
                  }}
                  onClick={() => fileInputRef.current?.click()}
                >
                  {uploadProgress > 0 ? (
                    <Box>
                      <CircularProgress
                        variant="determinate"
                        value={uploadProgress}
                        size={60}
                        thickness={4}
                        sx={{ mb: 2 }}
                      />
                      <Typography variant="h6" gutterBottom>
                        Upload en cours... {uploadProgress}%
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        sx={{ height: 8, borderRadius: 4 }}
                      />
                    </Box>
                  ) : (
                    <>
                      <CloudUpload sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />
                      <Typography variant="h5" gutterBottom>
                        Glissez votre vidéo ici
                      </Typography>
                      <Typography color="textSecondary">
                        ou cliquez pour sélectionner un fichier
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ mt: 2 }}
                        startIcon={<MovieFilter />}
                      >
                        Sélectionner une vidéo
                      </Button>
                    </>
                  )}
                </DropZone>
              </Box>
            </Fade>
          )}

          {/* Étape 2: Configuration */}
          {currentStep === 1 && videoInfo && (
            <Fade in>
              <Box>
                <Grid container spacing={4}>
                  {/* Informations de la vidéo */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={3}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                          <MovieFilter sx={{ mr: 1 }} /> Informations de la vidéo
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body2" color="textSecondary">
                                Dimensions
                              </Typography>
                              <Typography variant="body1">
                                {videoInfo.dimensions}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2" color="textSecondary">
                                Bitrate Vidéo
                              </Typography>
                              <Typography variant="body1">
                                {formatBitrate(videoInfo.bitrate)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2" color="textSecondary">
                                Bitrate Audio
                              </Typography>
                              <Typography variant="body1">
                                {videoInfo.audio_bitrate ? formatBitrate(videoInfo.audio_bitrate) : 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2" color="textSecondary">
                                Taille
                              </Typography>
                              <Typography variant="body1">
                                {formatSize(videoInfo.size)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2" color="textSecondary">
                                Volume Moyen
                              </Typography>
                              <Typography variant="body1" sx={{
                                color: videoInfo.mean_volume_db > -10 ? 'error.main' : 
                                       videoInfo.mean_volume_db > -20 ? 'warning.main' : 
                                       'success.main'
                              }}>
                                {videoInfo.mean_volume_db ? `${videoInfo.mean_volume_db.toFixed(1)} dB` : 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2" color="textSecondary">
                                Volume Maximum
                              </Typography>
                              <Typography variant="body1" sx={{
                                color: videoInfo.max_volume_db > -6 ? 'error.main' : 
                                       videoInfo.max_volume_db > -12 ? 'warning.main' : 
                                       'success.main'
                              }}>
                                {videoInfo.max_volume_db ? `${videoInfo.max_volume_db.toFixed(1)} dB` : 'N/A'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Paramètres */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={3}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                          <Settings sx={{ mr: 1 }} /> Paramètres de sortie
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                          <TextField
                            label="Dimensions"
                            value={parameters.dimensions}
                            onChange={(e) => handleParameterChange('dimensions', e.target.value)}
                            placeholder={`ex: ${videoInfo.dimensions}`}
                            InputProps={{
                              startAdornment: <AspectRatio sx={{ mr: 1, color: 'text.secondary' }} />,
                            }}
                            helperText={modifiedParams.dimensions ? "Ce paramètre sera modifié" : "Inchangé"}
                          />

                          <TextField
                            label="Bitrate Vidéo"
                            value={parameters.bitrate}
                            onChange={(e) => handleParameterChange('bitrate', e.target.value)}
                            placeholder={`ex: ${formatBitrate(videoInfo.bitrate)}`}
                            InputProps={{
                              startAdornment: <HighQuality sx={{ mr: 1, color: 'text.secondary' }} />,
                            }}
                            helperText={modifiedParams.bitrate ? "Ce paramètre sera modifié" : "Inchangé"}
                          />

                          <TextField
                            label="Bitrate Audio"
                            value={parameters.audioBitrate}
                            onChange={(e) => handleParameterChange('audioBitrate', e.target.value)}
                            placeholder={`ex: ${videoInfo.audio_bitrate ? formatBitrate(videoInfo.audio_bitrate) : '128k'}`}
                            InputProps={{
                              startAdornment: <VolumeUp sx={{ mr: 1, color: 'text.secondary' }} />,
                            }}
                            helperText={modifiedParams.audioBitrate ? "Ce paramètre sera modifié" : "Inchangé"}
                          />

                          <Box>
                            <Typography gutterBottom>Ajustement du Volume (dB)</Typography>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <VolumeUp />
                              </Grid>
                              <Grid item xs>
                                <Slider
                                  value={parameters.volume}
                                  onChange={(e, newValue) => handleParameterChange('volume', newValue)}
                                  min={-30}
                                  max={20}
                                  step={1}
                                  marks={[
                                    { value: -30, label: '-30dB' },
                                    { value: -20, label: '-20dB' },
                                    { value: 0, label: '0dB' },
                                    { value: 20, label: '+20dB' },
                                  ]}
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={value => `${value}dB`}
                                  sx={{
                                    '& .MuiSlider-markLabel': {
                                      fontSize: '0.8rem'
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Typography variant="caption" color="textSecondary">
                              {modifiedParams.volume ? "Le volume sera modifié" : "Volume inchangé"}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                {/* Boutons d'action */}
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  gap: 2, 
                  mt: 4 
                }}>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={resetUpload}
                    startIcon={<ArrowBack />}
                  >
                    Changer de vidéo
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleProcessVideo}
                    startIcon={<Speed />}
                    disabled={!Object.values(modifiedParams).some(v => v)}
                  >
                    Lancer le traitement
                  </Button>
                </Box>
              </Box>
            </Fade>
          )}

          {/* Étape 3: Traitement */}
          {currentStep === 2 && (
            <Fade in>
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress
                  variant="determinate"
                  value={processingProgress}
                  size={120}
                  thickness={4}
                  sx={{ mb: 4 }}
                />
                <Typography variant="h5" gutterBottom>
                  Traitement en cours... {processingProgress}%
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Veuillez patienter pendant que nous traitons votre vidéo
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={processingProgress}
                  sx={{ height: 8, borderRadius: 4, mt: 2 }}
                />
              </Box>
            </Fade>
          )}

          {/* Étape 4: Téléchargement */}
          {currentStep === 3 && processedVideo && (
            <Fade in>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom sx={{ color: 'success.main' }}>
                  ✨ Traitement terminé avec succès ! ✨
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  gap: 2,
                  mt: 3 
                }}>
                  <Button
                    variant="contained"
                    size="large"
                    href={api.getDownloadUrl(processedVideo)}
                    startIcon={<Download />}
                    sx={{ minWidth: '200px' }}
                  >
                    Télécharger la vidéo
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={resetUpload}
                    startIcon={<ArrowBack />}
                    sx={{ minWidth: '200px' }}
                  >
                    Nouvelle vidéo
                  </Button>
                </Box>
              </Box>
            </Fade>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default VideoProcessor;