import axios from 'axios';

const API_URL = 'https://freewheel.fr/api';

const api = {
 uploadVideo: async (file, onProgress) => {
   try {
     console.log('Starting upload with file:', file);
     const formData = new FormData();
     formData.append('video', file);

     const response = await axios.post(`${API_URL}/upload`, formData, {
       headers: {
         'Content-Type': 'multipart/form-data'
       },
       onUploadProgress: (progressEvent) => {
         const percentCompleted = Math.round(
           (progressEvent.loaded * 100) / progressEvent.total
         );
         console.log('Upload progress:', percentCompleted);
         onProgress && onProgress(percentCompleted);
       }
     });

     console.log('Upload response:', response.data);
     return response.data;
   } catch (error) {
     console.error('Upload error details:', {
       message: error.message,
       response: error.response?.data,
       status: error.response?.status
     });
     if (error.response?.data?.error) {
       throw new Error(error.response.data.error);
     }
     throw new Error('Erreur serveur');
   }
 },

 processVideo: async (filename, parameters) => {
   try {
     const response = await axios.post(`${API_URL}/process`, {
       filename,
       parameters
     });
     return response.data;
   } catch (error) {
     if (error.response?.data?.error) {
       throw new Error(error.response.data.error);
     }
     throw new Error('Erreur lors du traitement');
   }
 },

 getDownloadUrl: (filename) => `${API_URL}/download/${filename}`
};

export default api;
